import React, { memo } from 'react';
import { useWtrSelector } from 'redux/hooks';
import history from 'utils/history';
import {
  getNextInterstitialsPath,
  isInterstitialLoading,
} from 'redux/modules/interstitials/selectors';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import GoToCheckout from 'components/GoToCheckout';
import { MessagePlacement } from 'components/GoToCheckout/GoToCheckout';
import styles from 'components/ProceedThroughCheckout/ProceedThroughCheckout.scss';
import { yieldToMain } from 'utils/yield-to-main';

type ProceedThroughCheckoutProps = {
  fullWidth?: boolean;
  messagePlacement: MessagePlacement;
  onInterstitialsPage?: boolean;
  location?: string;
  handleClick?: () => unknown;
  buttonCheckoutId?: string;
  theme?: 'primary' | 'primaryWhite' | 'secondary' | 'secondaryLight' | 'finalising';
  useLightTooltip?: boolean;
};

const ProceedThroughCheckout = memo(
  ({
    fullWidth = false,
    messagePlacement,
    onInterstitialsPage = false,
    location = '',
    handleClick = () => {},
    buttonCheckoutId,
    theme,
    useLightTooltip = false,
  }: ProceedThroughCheckoutProps) => {
    const next = useWtrSelector(getNextInterstitialsPath);
    const loading = useWtrSelector(isInterstitialLoading);

    const handleOnClick = async () => {
      handleClick();
      await yieldToMain();
      history.push(next);
    };

    return next || loading ? (
      <div className={styles.proceedThroughCheckout}>
        <Button
          disabled={loading}
          onClick={handleOnClick}
          data-test="interstitials-next"
          data-test-context={onInterstitialsPage ? 'page' : 'header'}
          label={onInterstitialsPage ? 'Next step' : 'Checkout'}
          theme={theme ?? 'finalising'}
          type="button"
          width="fit"
        />
      </div>
    ) : (
      <GoToCheckout
        fullWidth={fullWidth}
        theme={theme}
        messagePlacement={messagePlacement}
        location={location}
        buttonCheckoutId={buttonCheckoutId}
        canShowInstantCheckoutModal={['checkout-amend-order-alert'].includes(
          buttonCheckoutId ?? '',
        )}
        useLightTooltip={useLightTooltip}
      />
    );
  },
);

ProceedThroughCheckout.displayName = 'ProceedThroughCheckout';

export default ProceedThroughCheckout;
